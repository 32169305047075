import StandardDescriptionBlock from "../pages/portfolio-page/sections/description-section/standard-description-block/standard-description-block";

export interface PortfolioItemDto {
  id: string;
  title: string;
  pretitle: string;
  cover: string;
  images: Array<string>;
  disabled?: boolean;
  primaryColor: string;
  fontsColor: string;
  description: (primaryColor: string, fontsColor: string) => React.ReactNode;
}

export const PortfolioData: PortfolioItemDto[] = [
  {
    id: "thrace",
    title: "Thrace",
    pretitle: "Crypto Wallet",
    cover: "/images/portfolio/thrace/cover.jpg",
    primaryColor: "#A141FF",
    fontsColor: "light",
    images: [
      "/images/portfolio/thrace/1.jpg",
      "/images/portfolio/thrace/2.jpg",
      "/images/portfolio/thrace/3.jpg",
      "/images/portfolio/thrace/4.jpg",
      "/images/portfolio/thrace/5.jpg",
      "/images/portfolio/thrace/6.jpg",
      "/images/portfolio/thrace/7.jpg",
    ],
    description: (primaryColor: string, fontsColor: string) => (
      <StandardDescriptionBlock primaryColor={primaryColor} fontsColor={fontsColor} buttonTitle={"Ask for a file review"}>
        <p>
          If there is any crypto wallet that is friendly to new users, it is Thrace. Of course no one will feel comfortable with it right away, for that you
          need basic knowledge of cryptocurrencies. But it is this tool that will come closest to ease of use. Available in the second half of 2024.
        </p>
      </StandardDescriptionBlock>
    ),
  },
  {
    id: "brain-waves",
    pretitle: "Noise generation",
    title: "Brain Waves",
    cover: "/images/portfolio/brain-waves/cover.jpg",
    primaryColor: "#ED2B2B",
    fontsColor: "light",
    images: [
      "/images/portfolio/brain-waves/1.jpg",
      "/images/portfolio/brain-waves/2.jpg",
      "/images/portfolio/brain-waves/3.jpg",
      "/images/portfolio/brain-waves/4.jpg",
      "/images/portfolio/brain-waves/5.jpg",
      "/images/portfolio/brain-waves/6.jpg",
    ],
    description: (primaryColor: string, fontsColor: string) => (
      <StandardDescriptionBlock primaryColor={primaryColor} fontsColor={fontsColor} buttonTitle={"Ask for a file review"}>
        <p>
          I appreciate this little application for its ease of use and great noise generation capabilities, which allow me to survive in the city, better focus
          on a book, or improve my work speed. So I decided to refresh its interface and branding just a little bit.
        </p>
      </StandardDescriptionBlock>
    ),
  },
  {
    id: "mynoise",
    pretitle: "Noise generation",
    title: "myNoise",
    cover: "/images/portfolio/mynoise/cover.jpg",
    primaryColor: "#13F287",
    fontsColor: "dark",
    images: [
      "/images/portfolio/mynoise/1.jpg",
      "/images/portfolio/mynoise/2.jpg",
      "/images/portfolio/mynoise/3.jpg",
      "/images/portfolio/mynoise/5.jpg",
      "/images/portfolio/mynoise/6.jpg",
      "/images/portfolio/mynoise/8.jpg",
      "/images/portfolio/mynoise/9.jpg",
      "/images/portfolio/mynoise/10.jpg",
    ],
    description: (primaryColor: string, fontsColor: string) => (
      <StandardDescriptionBlock primaryColor={primaryColor} fontsColor={fontsColor} buttonTitle={"Ask for a file review"}>
        <p>
          As with BrainWaves, myNoise is a small application with very powerful sound modification capabilities. In this case a bit more complex sounds - from
          the hum of bees to the waves of the ocean to the chants of Tibetan monks.
        </p>
      </StandardDescriptionBlock>
    ),
  },
  {
    id: "prego",
    pretitle: "Prego",
    title: "Prego",
    cover: "/images/portfolio/prego/cover.jpg",
    disabled: true,
    primaryColor: "#ffdd65",
    fontsColor: "dark",
    images: ["/images/portfolio/prego/1.jpg"],
    description: (primaryColor: string, fontsColor: string) => (
      <StandardDescriptionBlock primaryColor={primaryColor} fontsColor={fontsColor} buttonTitle={"Ask for a file review"}>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias, quisquam? Corporis vitae eos illum facilis, magnam quasi aut laboriosam reiciendis
          et sit pariatur unde iste ex necessitatibus? Repellendus, nostrum adipisci?
        </p>
      </StandardDescriptionBlock>
    ),
  },
];
