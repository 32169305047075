/* eslint-disable jsx-a11y/anchor-has-content */
import "./footer.scss";
import { Col, Container, Row } from "codeebo-react-grid";
import Button from "../../../libs/buttons/Button/Button";
import { useLocation } from "react-router-dom";
import { PortfolioData } from "../../_data/PortfolioData";

import Typewriter from "typewriter-effect";

const pauseMs = 2000;

const Footer = () => {
  const { pathname } = useLocation();
  // const id = pathname.split("/projects/").findLast((x) => x);
  const arr = pathname.split("/projects/");
  const id = arr.slice().reverse().find((x: any) => x);
  const portfolioItem = PortfolioData.find((x) => x.id === id);
  const styles = portfolioItem ? { background: portfolioItem.primaryColor } : {};
  return (
    <footer className={`footer pt-5 ${portfolioItem ? "stylevariant-" + portfolioItem.fontsColor : ""}`} data-aos="fade-in">
      <div className={`primary-tile py-md-5 py-0`} style={{ ...styles }}>
        <Container className="py-5">
          <Row>
            <Col size={[5, 6, 6, 7]} offset={[1, 1, 0]} className="first-col">
              <h2 className="pb-4 d-flex">
                Let's&nbsp;
                <Typewriter
                  options={{
                    loop: true,
                  }}
                  onInit={(typewriter) => {
                    typewriter
                      .typeString(`talk`)
                      .pauseFor(pauseMs)
                      .deleteAll()
                      .typeString(`work`)
                      .pauseFor(pauseMs)
                      .deleteAll()
                      .typeString(`cooperate`)
                      .pauseFor(pauseMs)
                      .deleteAll()
                      .start();
                  }}
                />
              </h2>

              <a href="mailto:contact@stuxpid.com" className="contact-me-btn mb-4">
                <Button variant="primary-icon">
                  Contact Me <img className="ml-2 my-auto" src="/images/system-icons/right-arrow-icon.svg" alt="->" />
                </Button>
              </a>
            </Col>
            <Col size={[4, 4, 6, 5]} offset={[1, 1, 0]} className="links">
              <a href="mailto:contact@stuxpid.com">contact@stuxpid.com</a> <br />
              <a href="https://www.spnm.pl/" target="_blank" rel="noreferrer">
                Visit spnm site <img src="/images/system-icons/outside-link-icon.svg" alt="arrow top right" />
              </a>{" "}
              <br />
              <a href="https://www.linkedin.com/in/szymon-nowak-mieszkie%C5%82%C5%82o-20462026b/" target="_blank" rel="noreferrer">
                LinkedIn profile <img src="/images/system-icons/outside-link-icon.svg" alt="arrow top right" />
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
